import {
  GET_TEAMS_IN_SCHEDULES,
  DELETE_TEAMS_IN_SCHEDULE,
  FETCH_TEAMS_IN_SCHEDULE,
  EDIT_TEAMS_IN_SCHEDULE,
  ADD_TEAMS_IN_SCHEDULE,
  CLEAR_TEAMS_IN_SCHEDULE,
  CLEAR_ALL,

} from "constants/actions";

const initialState = {
  teams_in_schedules: [],
  pending: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TEAMS_IN_SCHEDULES:
      return {
        ...state,
        teams_in_schedules: action.payload,
        pending: false,
      };

    case FETCH_TEAMS_IN_SCHEDULE:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_TEAMS_IN_SCHEDULE:
      const schedule = state.teams_in_schedules.filter(
        (row) => row.id !== action.payload.id
      );
      schedule.push(action.payload);
      return { ...state, teams_in_schedules: schedule };

    case DELETE_TEAMS_IN_SCHEDULE:
      return {
        ...state,
        teams_in_schedules: state.teams_in_schedules.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_TEAMS_IN_SCHEDULE:
      return {
        ...state,
        teams_in_schedules: [...state.teams_in_schedules, action.payload],
      };

    case CLEAR_TEAMS_IN_SCHEDULE:
      return {
        ...state,
        teams_in_schedules: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        teams_in_schedules: [],
      };

    default:
      return state;
  }
}
