import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_DEBRIEF_SUMMARY_PENDING,
  GET_DEBRIEF_SUMMARY,
  SG_GET_DEBRIEF_SUMMARY,
  GET_DEBRIEF_SUMMARY_NEXT,
  SG_GET_DEBRIEF_SUMMARY_NEXT,
  DELETE_DEBRIEF_SUMMARY,
  SG_DELETE_DEBRIEF_SUMMARY,
  FETCH_DEBRIEF_SUMMARY,
  SG_FETCH_DEBRIEF_SUMMARY,
  EDIT_DEBRIEF_SUMMARY,
  SG_EDIT_DEBRIEF_SUMMARY,
  ADD_DEBRIEF_SUMMARY,
  SG_ADD_DEBRIEF_SUMMARY,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
    yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_DEBRIEF_SUMMARY, action.payload);
    yield put({ type: GET_DEBRIEF_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_DEBRIEF_SUMMARY, loadmethods);
}

function* loadNextmethods(action) {
  yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
// console.log('action.payload', action.payload)
try {
  const json = yield call(api.GET_DEBRIEF_SUMMARY_NEXT, action.payload);
  yield put({ type: GET_DEBRIEF_SUMMARY_NEXT, payload: json.data });

} catch (e) {
  yield call(handleError, e);
}
}

export function* methodNextLoad() {
yield takeLatest(SG_GET_DEBRIEF_SUMMARY_NEXT, loadNextmethods);
}


function* fetchmethods(action) {
  yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
  try {
    const json = yield call(api.FETCH_DEBRIEF_SUMMARY, action.payload);
    yield put({ type: FETCH_DEBRIEF_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_SUMMARY, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
  try {
    const json = yield call(api.EDIT_DEBRIEF_SUMMARY, action.payload);
    yield put({ type: EDIT_DEBRIEF_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_DEBRIEF_SUMMARY, updatemethods);
}


function* addmethods(action) {
  yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
  try {
    const json = yield call(api.ADD_DEBRIEF_SUMMARY, action.payload);
    yield put({ type: ADD_DEBRIEF_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_DEBRIEF_SUMMARY, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_DEBRIEF_SUMMARY_PENDING });
  try {
    yield call(api.DELETE_DEBRIEF_SUMMARY, action.payload);
    yield put({ type: DELETE_DEBRIEF_SUMMARY, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_SUMMARY, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodNextLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
