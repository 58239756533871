import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SUBSCRIPTIONS,
  SG_GET_SUBSCRIPTIONS,
  DELETE_SUBSCRIPTION,
  SG_DELETE_SUBSCRIPTION,
  SG_FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION,
  SG_EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION,
  SG_ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION,
  GET_STRIPE_TAX_RATES,
  SG_GET_STRIPE_TAX_RATES,
  NEXT_INVOICE,
  SG_NEXT_INVOICE,
  GET_STRIPE_PAYMENT_METHODS,
  SG_GET_STRIPE_PAYMENT_METHODS,
  CANCEL_SUBSCRIPTION,
  SG_CANCEL_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  SG_UPDATE_SUBSCRIPTION,
  UPGRADE_PLAN,
  SG_UPGRADE_PLAN,
  DOWNGRADE_PLAN,
  SG_DOWNGRADE_PLAN,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* nextInvoice(action) {
  try {
    const json = yield call(api.GET_NEXT_INVOICE, action.payload);
    yield put({ type: NEXT_INVOICE, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}


export function* loadNextInvoice() {
  yield takeLatest(SG_NEXT_INVOICE, nextInvoice);
}


function* stripeTaxRates(action) {
  try {
    const json = yield call(api.GET_STRIPE_TAX_RATES, action.payload);
    yield put({ type: GET_STRIPE_TAX_RATES, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}


export function* loadStripeTaxRates() {
  yield takeLatest(SG_GET_STRIPE_TAX_RATES, stripeTaxRates);
}

function* paymentMethods(action) {
  try {
    const json = yield call(api.GET_STRIPE_PAYMENT_METHODS, action.payload);
    yield put({ type: GET_STRIPE_PAYMENT_METHODS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}


export function* loadPaymentMethods() {
  yield takeLatest(SG_GET_STRIPE_PAYMENT_METHODS, paymentMethods);
}


function* cancelSubcription(action) {
  try {
    const json = yield call(api.CANCEL_SUBSCRIPTION, action.payload);
    yield put({ type: CANCEL_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loadCancelSubscription() {
  yield takeLatest(SG_CANCEL_SUBSCRIPTION, cancelSubcription);
}

function* updateSubcription(action) {
  try {
    const json = yield call(api.EDIT_SUBSCRIPTION, action.payload);
    yield put({ type: UPDATE_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loadUpdateSubscription() {
  yield takeLatest(SG_UPDATE_SUBSCRIPTION, updateSubcription);
}

function* upgradePlan(action) {
  try {
    const json = yield call(api.UPGRADE_PLAN, action.payload);
    yield put({ type: UPGRADE_PLAN, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loadUpgradePlan() {
  yield takeLatest(SG_UPGRADE_PLAN, upgradePlan);
}

function* downgradePlan(action) {
  try {
    const json = yield call(api.DOWNGRADE_PLAN, action.payload);
    yield put({ type: DOWNGRADE_PLAN, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loadDowngradePlan() {
  yield takeLatest(SG_DOWNGRADE_PLAN, downgradePlan);
}


// 
function* loadpayments(action) {
  try {
    const json = yield call(api.GET_SUBSCRIPTIONS, action.payload);
    yield put({ type: GET_SUBSCRIPTIONS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentLoad() {
  yield takeLatest(SG_GET_SUBSCRIPTIONS, loadpayments);
}

function* fetchpayments(action) {
  try {
    const json = yield call(api.FETCH_SUBSCRIPTION, action.payload);
    yield put({ type: FETCH_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentFetch() {
  yield takeLatest(SG_FETCH_SUBSCRIPTION, fetchpayments);
}

function* updatepayments(action) {
  try {
    const json = yield call(api.EDIT_SUBSCRIPTION, action.payload);
    yield put({ type: EDIT_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentUpdate() {
  yield takeLatest(SG_EDIT_SUBSCRIPTION, updatepayments);
}

function* addpayments(action) {
  try {
    const json = yield call(api.ADD_SUBSCRIPTION, action.payload);
    yield put({ type: ADD_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentAdd() {
  yield takeLatest(SG_ADD_SUBSCRIPTION, addpayments);
}

function* deletepayments(action) {
  try {
    const json = yield call(api.DELETE_SUBSCRIPTION, action.payload);
    yield put({ type: DELETE_SUBSCRIPTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentDelete() {
  yield takeLatest(SG_DELETE_SUBSCRIPTION, deletepayments);
}


export default function* index() {
  yield all([
    paymentLoad(),
    loadStripeTaxRates(),
    paymentFetch(),
    paymentUpdate(),
    paymentAdd(),
    paymentDelete(),
    loadNextInvoice(),
    loadCancelSubscription(),
    loadUpdateSubscription(),
    loadUpgradePlan(),
    loadDowngradePlan(),
    loadPaymentMethods(),
  ]);
}
