import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_PRODUCT_PENDING,
  GET_PRODUCT,
  SG_GET_PRODUCT,
  GET_PRODUCT_SELECT,
  SG_GET_PRODUCT_SELECT,
  DELETE_PRODUCT,
  SG_DELETE_PRODUCT,
  FETCH_PRODUCT,
  SG_FETCH_PRODUCT,
  EDIT_PRODUCT,
  SG_EDIT_PRODUCT,
  ADD_PRODUCT,
  SG_ADD_PRODUCT,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* processGet(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT, action.payload);
    yield put({ type: GET_PRODUCT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* getProcess() {
  yield takeLatest(SG_GET_PRODUCT, processGet);
}

function* processSelect(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT_SELECT, action.payload);
    yield put({ type: GET_PRODUCT_SELECT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* selectProcess() {
  yield takeLatest(SG_GET_PRODUCT_SELECT, processSelect);
}


function* fetchpayments(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  try {
    const json = yield call(api.FETCH_PRODUCT, action.payload);
    yield put({ type: FETCH_PRODUCT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentFetch() {
  yield takeLatest(SG_FETCH_PRODUCT, fetchpayments);
}

function* updatepayments(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  try {
    const json = yield call(api.EDIT_PRODUCT, action.payload);
    yield put({ type: EDIT_PRODUCT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentUpdate() {
  yield takeLatest(SG_EDIT_PRODUCT, updatepayments);
}

function* addpayments(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  try {
    const json = yield call(api.ADD_PRODUCT, action.payload);
    yield put({ type: ADD_PRODUCT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentAdd() {
  yield takeLatest(SG_ADD_PRODUCT, addpayments);
}

function* deletepayments(action) {
  yield put({ type: GET_PRODUCT_PENDING });
  try {
    const json = yield call(api.DELETE_PRODUCT, action.payload);
    yield put({ type: DELETE_PRODUCT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* paymentDelete() {
  yield takeLatest(SG_DELETE_PRODUCT, deletepayments);
}

export default function* index() {
  yield all([
    getProcess(),
    selectProcess(),
    paymentFetch(),
    paymentUpdate(),
    paymentAdd(),
    paymentDelete(),
  ]);
}
