import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_TEAMS,
    LOAD_TEAMS,
    DELETE_TEAM,
    REMOVE_TEAM,
    PULL_TEAM,
    FETCH_TEAM,
    UPDATE_TEAM,
    EDIT_TEAM,
    SAVE_TEAM,
    ADD_TEAM,
    CLEAR_TEAM,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadteams(action) {
//   yield put({ type: GET_teamS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_TEAMS, action.payload);
    yield put({ type: GET_TEAMS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamLoad() {
  yield takeLatest(LOAD_TEAMS, loadteams);
}

function* fetchteams(action) {
  try {
    const json = yield call(api.FETCH_TEAM, action.payload);
    yield put({ type: FETCH_TEAM, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamFetch() {
  yield takeLatest(PULL_TEAM, fetchteams);
}

function* updateteams(action) {
  try {
    const json = yield call(api.EDIT_TEAM, action.payload);
    yield put({ type: EDIT_TEAM, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamUpdate() {
  yield takeLatest(UPDATE_TEAM, updateteams);
}

function* addteams(action) {
  try {
    const json = yield call(api.ADD_TEAM, action.payload);
    yield put({ type: ADD_TEAM, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamAdd() {
  yield takeLatest(SAVE_TEAM, addteams);
}

function* deleteteams(action) {
  try {
    yield call(api.DELETE_TEAM, action.payload);
    yield put({ type: DELETE_TEAM, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamDelete() {
  yield takeLatest(REMOVE_TEAM, deleteteams);
}


export default function* index() {
  yield all([
    teamLoad(),
    teamFetch(),
    teamUpdate(),
    teamAdd(),
    teamDelete(),
  ]);
}
