import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_DEBRIEF_PDF_REPORT_PENDING,
  GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  GET_DEBRIEF_PDF_REPORT,
  SG_GET_DEBRIEF_PDF_REPORT,
  FETCH_DEBRIEF_PDF_REPORT,
  SG_FETCH_DEBRIEF_PDF_REPORT,
  // CREATE_DEBRIEF_PDF_REPORT,
  // SG_CREATE_DEBRIEF_PDF_REPORT,
  DELETE_DEBRIEF_PDF_REPORT,
  SG_DELETE_DEBRIEF_PDF_REPORT,
  UPDATE_DEBRIEF_PDF_REPORT,
  SG_UPDATE_DEBRIEF_PDF_REPORT,
  SG_REGENERATE_DEBRIEF_PDF_REPORT,
  REGENERATE_DEBRIEF_PDF_REPORT,
  SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK,
  GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* createMethodList(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });
  try {
    const json = yield call(api.GET_DEBRIEF_PDF_REPORT_TEMPLATES, action.payload);
    yield put({ type: GET_DEBRIEF_PDF_REPORT_TEMPLATES, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodList() {
  yield takeLatest(SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES, createMethodList);
}

function* getMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });
  try {
    const json = yield call(api.GET_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: GET_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodGet() {
  yield takeLatest(SG_GET_DEBRIEF_PDF_REPORT, getMethod);
}


function* fetchMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });
  try {
    const json = yield call(api.FETCH_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: FETCH_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_PDF_REPORT, fetchMethod);
}

function* updateMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });

  try {
    const json = yield call(api.UPDATE_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: UPDATE_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_UPDATE_DEBRIEF_PDF_REPORT, updateMethod);
}

function* regenerateMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });

  try {
    const json = yield call(api.REGENERATE_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: REGENERATE_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodRegenerate() {
  yield takeLatest(SG_REGENERATE_DEBRIEF_PDF_REPORT, regenerateMethod);
}


function* linkMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });

  try {
    const json = yield call(api.GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK, action.payload);
    yield put({ type: GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLink() {
  yield takeLatest(SG_GENERATE_SECURE_AWS_S3_DEBRIEF_PDF_REPORT_LINK, linkMethod);
}

// function* createMethod(action) {
//   try {
//     const json = yield call(api.UPDATE_PDF_SAVED_COMMENTS, action.payload);
//     yield put({ type: CREATE_DEBRIEF_PDF_REPORT, payload: json.data });
//   } catch (e) {
//     yield call(handleError, e);
//   }
// }

// export function* methodCreate() {
//   yield takeLatest(SG_CREATE_DEBRIEF_PDF_REPORT, createMethod);
// }

function* deleteMethod(action) {
  yield put({ type: GET_DEBRIEF_PDF_REPORT_PENDING });
  try {
    const json = yield call(api.DELETE_DEBRIEF_PDF_REPORT, action.payload.id);
    yield put({ type: DELETE_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_PDF_REPORT, deleteMethod);
}

export default function* index() {
  yield all([
    methodList(),
    methodGet(),
    methodFetch(),
    methodUpdate(),
    // methodCreate(),
    methodDelete(),
    methodRegenerate(),
    methodLink(),
  ]);
}
