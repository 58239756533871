import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";
import { handleError } from "./ApiError";

import {
    GET_ACTIVITY_LOG_PENDING,
    GET_ACTIVITY_LOGS,
    SG_GET_ACTIVITY_LOGS,
    DELETE_ACTIVITY_LOG,
    SG_DELETE_ACTIVITY_LOG,
    FETCH_ACTIVITY_LOG,
    SG_FETCH_ACTIVITY_LOG,
    FETCH_ACTIVITY_LOG_STATUS,
    SG_FETCH_ACTIVITY_LOG_STATUS,
    EDIT_ACTIVITY_LOG,
    SG_EDIT_ACTIVITY_LOG,
    ADD_ACTIVITY_LOG,
    SG_ADD_ACTIVITY_LOG,
    ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
    yield put({ type: GET_ACTIVITY_LOG_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ACTIVITY_LOGS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_ACTIVITY_LOGS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_ACTIVITY_LOGS, loadmethods);
}


function* fetchmethods(action) {
    yield put({ type: GET_ACTIVITY_LOG_PENDING });
  try {
    const json = yield call(api.FETCH_ACTIVITY_LOG, action.payload);
    yield put({ type: FETCH_ACTIVITY_LOG, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_ACTIVITY_LOG, fetchmethods);
}

function* fetchmethodstatus(action) {
    yield put({ type: GET_ACTIVITY_LOG_PENDING });
  try {
    const json = yield call(api.FETCH_ACTIVITY_LOG_STATUS, action.payload);
    yield put({ type: FETCH_ACTIVITY_LOG_STATUS, payload: {id: action.payload.id, ...json.data} });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetchStatus() {
  yield takeLatest(SG_FETCH_ACTIVITY_LOG_STATUS, fetchmethodstatus);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_ACTIVITY_LOG, action.payload);
    yield put({ type: EDIT_ACTIVITY_LOG, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_ACTIVITY_LOG, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_GPT_MEMBER
  try {
    const json = yield call(api.ADD_ACTIVITY_LOG, action.payload);
    yield put({ type: ADD_ACTIVITY_LOG, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_ACTIVITY_LOG, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_ACTIVITY_LOG, action.payload);
    yield put({ type: DELETE_ACTIVITY_LOG, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_ACTIVITY_LOG, deletemethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
    methodFetchStatus(),
  ]);
}
