import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_PRODUCT_PACKAGE_PENDING,
  GET_PRODUCT_PACKAGE,
  SG_GET_PRODUCT_PACKAGE,
  DUPLICATE_PRODUCT_PACKAGE,
  SG_DUPLICATE_PRODUCT_PACKAGE,
  GET_PRODUCT_PACKAGE_SELECT,
  SG_GET_PRODUCT_PACKAGE_SELECT,
  DELETE_PRODUCT_PACKAGE,
  SG_DELETE_PRODUCT_PACKAGE,
  FETCH_PRODUCT_PACKAGE,
  SG_FETCH_PRODUCT_PACKAGE,
  EDIT_PRODUCT_PACKAGE,
  SG_EDIT_PRODUCT_PACKAGE,
  ADD_PRODUCT_PACKAGE,
  SG_ADD_PRODUCT_PACKAGE,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadprocess(action) {
  yield put({ type: GET_PRODUCT_PACKAGE_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT_PACKAGE, action.payload);
    yield put({ type: GET_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processLoad() {
  yield takeLatest(SG_GET_PRODUCT_PACKAGE, loadprocess);
}

function* selectprocess(action) {
  yield put({ type: GET_PRODUCT_PACKAGE_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_PRODUCT_PACKAGE_SELECT, action.payload);
    yield put({ type: GET_PRODUCT_PACKAGE_SELECT, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processSelect() {
  yield takeLatest(SG_GET_PRODUCT_PACKAGE_SELECT, selectprocess);
}

function* fetchprocess(action) {
  try {
    const json = yield call(api.FETCH_PRODUCT_PACKAGE, action.payload);
    yield put({ type: FETCH_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processFetch() {
  yield takeLatest(SG_FETCH_PRODUCT_PACKAGE, fetchprocess);
}

function* updateprocess(action) {
  try {
    const json = yield call(api.EDIT_PRODUCT_PACKAGE, action.payload);
    yield put({ type: EDIT_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processUpdate() {
  yield takeLatest(SG_EDIT_PRODUCT_PACKAGE, updateprocess);
}

function* duplicateprocess(action) {
  try {
    const json = yield call(api.DUPLICATE_PRODUCT_PACKAGE, action.payload);
    yield put({ type: DUPLICATE_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processDuplicate() {
  yield takeLatest(SG_DUPLICATE_PRODUCT_PACKAGE, duplicateprocess);
}

function* addprocess(action) {
  try {
    const json = yield call(api.ADD_PRODUCT_PACKAGE, action.payload);
    yield put({ type: ADD_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processAdd() {
  yield takeLatest(SG_ADD_PRODUCT_PACKAGE, addprocess);
}

function* deleteprocess(action) {
  try {
    const json = yield call(api.DELETE_PRODUCT_PACKAGE, action.payload);
    yield put({ type: DELETE_PRODUCT_PACKAGE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* processDelete() {
  yield takeLatest(SG_DELETE_PRODUCT_PACKAGE, deleteprocess);
}

export default function* index() {
  yield all([
    processLoad(),
    processSelect(),
    processFetch(),
    processUpdate(),
    processAdd(),
    processDuplicate(),
    processDelete(),
  ]);
}
