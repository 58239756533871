import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
    // UPDATE_GPT_MODEL_MAIN_PAGE_NUMBER,
    GET_GPT_SUMMARY_PENDING,
    GET_GPT_SUMMARY,
    SG_GET_GPT_SUMMARY,
    DELETE_GPT_SUMMARY,
    SG_DELETE_GPT_SUMMARY,
    FETCH_GPT_SUMMARY,
    SG_FETCH_GPT_SUMMARY,
    EDIT_GPT_SUMMARY,
    SG_EDIT_GPT_SUMMARY,
    ADD_GPT_SUMMARY,
    SG_ADD_GPT_SUMMARY,
    // CLEAR_GPT_SUMMARY,
    ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
    yield put({ type: GET_GPT_SUMMARY_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_GPT_SUMMARY, action.payload);
    // console.log(json.data)
    yield put({ type: GET_GPT_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_GPT_SUMMARY, loadmethods);
}

function* fetchmethods(action) {
    yield put({ type: GET_GPT_SUMMARY_PENDING });
  try {
    const json = yield call(api.FETCH_GPT_SUMMARY, action.payload);
    yield put({ type: FETCH_GPT_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_GPT_SUMMARY, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_GPT_SUMMARY, action.payload);
    yield put({ type: EDIT_GPT_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_GPT_SUMMARY, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_GPT_SUMMARY
  try {
    const json = yield call(api.ADD_GPT_SUMMARY, action.payload);
    yield put({ type: ADD_GPT_SUMMARY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_GPT_SUMMARY, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_GPT_SUMMARY, action.payload);
    yield put({ type: DELETE_GPT_SUMMARY, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_GPT_SUMMARY, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
