import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SURVEY_STANDARD_PENDING,
  GET_SURVEY_STANDARDS,
  CLEAR_SERVER_CACHE,
  SG_CLEAR_SERVER_CACHE,
  GET_APP_SURVEY_STANDARDS,
  SG_GET_APP_SURVEY_STANDARDS,
  SG_GET_SURVEY_STANDARDS,
  DELETE_SURVEY_STANDARD,
  SG_DELETE_SURVEY_STANDARD,
  FETCH_SURVEY_STANDARD,
  SG_FETCH_SURVEY_STANDARD,
  EDIT_SURVEY_STANDARD,
  SG_EDIT_SURVEY_STANDARD,
  ADD_SURVEY_STANDARD,
  SG_ADD_SURVEY_STANDARD,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_SURVEY_STANDARDS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_SURVEY_STANDARDS, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_SURVEY_STANDARDS, loadmethods);
}

function* loadAppmethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_APP_SURVEY_STANDARDS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_APP_SURVEY_STANDARDS, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAppLoad() {
  yield takeLatest(SG_GET_APP_SURVEY_STANDARDS, loadAppmethods);
}

function* clearCacheMethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.CLEAR_SERVER_CACHE, action.payload);
    // console.log(json.data)
    yield put({ type: CLEAR_SERVER_CACHE, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodClearCache() {
  yield takeLatest(SG_CLEAR_SERVER_CACHE, clearCacheMethods);
}

function* fetchmethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.FETCH_SURVEY_STANDARD, action.payload);
    yield put({ type: FETCH_SURVEY_STANDARD, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_SURVEY_STANDARD, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.EDIT_SURVEY_STANDARD, action.payload);
    yield put({ type: EDIT_SURVEY_STANDARD, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_EDIT_SURVEY_STANDARD, updatemethods);
}

function* addmethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    const json = yield call(api.ADD_SURVEY_STANDARD, action.payload);
    yield put({ type: ADD_SURVEY_STANDARD, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_SURVEY_STANDARD, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_SURVEY_STANDARD_PENDING });
  try {
    yield call(api.DELETE_SURVEY_STANDARD, action.payload);
    yield put({ type: DELETE_SURVEY_STANDARD, payload: action.payload });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_SURVEY_STANDARD, deletemethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
    methodAppLoad(),
    methodClearCache(),
  ]);
}
