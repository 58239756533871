import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Select from "react-select";
import {
  SG_GET_PRODUCT_GROUP_SELECT,
  SG_FETCH_PRODUCT,
  SG_EDIT_PRODUCT,
  SG_ADD_PRODUCT,
  SG_DELETE_PRODUCT,
} from "constants/actions";
import {
  Icon,
  Message,
  Button,
  Header,
  Input,
  Checkbox,
  TextArea,
  Confirm,
} from "semantic-ui-react";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const STAGE_OPTIONS = [
  { value: "pre-alpha", label: "Pre Alpha" },
  { value: "alpha", label: "alpha" },
  { value: "beta", label: "Beta" },
  { value: "general_availability", label: "General Availability" },
  { value: "retired", label: "Retired" },
];

// pre-alpha, alpha, beta, general availability, and retired

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [prodGroupSelected, setProdGroupSelected] = useState([]);
  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: "",
    active: true,
    sort_order: 0,
    description: "",
    product_stage: "beta",  // default value
    version: "",
    associate_product: null,
  });
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  useEffect(() => {
    dispatch({
      type: SG_GET_PRODUCT_GROUP_SELECT,
    });
  }, [dispatch]);

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_PRODUCT,
        payload: { id: templateId },
      });
    }
  }, [dispatch, templateId]);

  const { get_product, get_product_group_select } = useSelector(
    (state) => ({
      get_product: state.product,
      get_product_group_select: state.product_group.select_list,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_product?.[templateId]) {
      setTemplateDetails(get_product?.[templateId]);
      setProdGroupSelected(get_product_group_select.find( pg => pg.id === get_product?.[templateId].product_group));
    }
  }, [get_product, templateId, get_product_group_select]);

  useEffect(() => {
    if (get_product?.updated?.id) {
      navigate(`/app/product/product/details/${get_product.updated.id}`);
    }
  }, [get_product.updated, navigate]);

  useEffect(() => {
    const ErrorList = [];
    
    // Validate name
    if (!templateDetails?.name) {
      ErrorList.push("Enter a name");
    }
 
    // Validate product_stage (ensure it's not empty)
    if (!templateDetails?.product_stage) {
      ErrorList.push("Select a product stage");
    }
  
    // Validate version (optional, but let's assume it should have a value if product_stage is 'live')
    if (templateDetails?.product_stage === 'live' && !templateDetails?.version) {
      ErrorList.push("Enter a version for live products");
    }
  
    // Validate sort_order (ensure it's a number and positive)
    if (templateDetails?.sort_order === null || templateDetails?.sort_order < 0) {
      ErrorList.push("Enter a valid positive number for sort order");
    }
  
    // Validate product group (ensure a product group is selected)
    if (!prodGroupSelected?.id) {
      ErrorList.push("Select a product group");
    }
  
    setFormErrors(ErrorList);
  }, [templateDetails, prodGroupSelected]);
  

  const handleChange = (e, { name, value }) => {
    setTemplateDetails({ ...templateDetails, [name]: value });
  };

  const handleCheckboxChange = (e, { name, checked }) => {
    setTemplateDetails({ ...templateDetails, [name]: checked });
  };

  const handleSubmit = () => {
    setPristine(false);
    if (formErrors.length === 0) {
      const payload = {
        ...templateDetails,
        product_group: prodGroupSelected.id,
      };
      if (templateId) {
        dispatch({
          type: SG_EDIT_PRODUCT,
          payload: { id: templateId, ...payload },
        });
      } else {
        dispatch({
          type: SG_ADD_PRODUCT,
          payload,
        });
      }
    }
  };

  const handleDelete = () => {
    if (templateId) {
      dispatch({
        type: SG_DELETE_PRODUCT,
        payload: { id: templateId },
      });
    }
  };

  function ChangeProductGroup(e) {
    setProdGroupSelected(e);
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/app/product`)}
          name="arrow left"
        />
        {templateId
          ? `Edit Details of ${templateDetails?.name}`
          : "Add New Product"}
      </Header>

      <BasicErrorMessage />
      {formErrors.length > 0 && !pristine && (
        <Message negative header="We need these details!" list={formErrors} />
      )}

      <FormColumn>
        {/* Existing Fields */}
        <FormSection>
          <LabelHead>Name</LabelHead>
          <StyledInput
            name="name"
            value={templateDetails?.name}
            onChange={handleChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Active</LabelHead>
          <StyledCheckbox
            name="active"
            checked={templateDetails?.active}
            onChange={handleCheckboxChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Unique Numeric Id (Sort Order)</LabelHead>
          <StyledInput
            name="sort_order"
            type="number"
            value={templateDetails?.sort_order}
            onChange={handleChange}
          />
        </FormSection>

        <FormSection>
          <LabelHead>Product Group</LabelHead>
          <Select
            name="product_group"
            options={get_product_group_select}
            onChange={(e) => ChangeProductGroup(e)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={prodGroupSelected}
          />
        </FormSection>

        {/* New Fields */}
        <FormSection>
          <LabelHead>Product Stage</LabelHead>
          <Select
            name="product_stage"
            options={STAGE_OPTIONS}
            value={STAGE_OPTIONS.find(
              (option) => option.value === templateDetails?.product_stage
            )}
            onChange={(selectedOption) =>
              handleChange(null, { name: "product_stage", value: selectedOption.value })
            }
          />
        </FormSection>

        <FormSection>
          <LabelHead>Version</LabelHead>
          <StyledInput
            name="version"
            value={templateDetails?.version}
            onChange={handleChange}
          />
        </FormSection>

        {/* <FormSection>
          <LabelHead>Related Product</LabelHead>
          <Select
            name="associate_product"
            options={get_product_group_select} // assuming associate product comes from product groups
            onChange={(e) => handleChange(null, { name: "associate_product", value: e.id })}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option.id}`}
            value={prodGroupSelected}
          />
        </FormSection> */}

        <FormSection>
          <LabelHead>Description</LabelHead>
          <StyledTextArea
            name="description"
            value={templateDetails.description}
            onChange={handleChange}
          />
        </FormSection>
      </FormColumn>

      <SectionDiv lastSection={true}>
        {templateId && (
          <>
            <Button
              floated="left"
              color="red"
              onClick={() => setConfirmOpen(true)}
            >
              Delete Product Group
            </Button>
            <Confirm
              open={confirmOpen}
              onCancel={() => setConfirmOpen(false)}
              onConfirm={handleDelete}
            />
          </>
        )}

        <Button floated="right" color="green" onClick={handleSubmit}>
          {templateId ? "Update" : "Create"}
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: 0;
`;

const StyledTextArea = styled(TextArea)`
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  min-height: 100px;
  resize: vertical;
  &:focus,
  &:hover {
    border-color: blue;
  }
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

