import { put, call, all, takeLatest } from "redux-saga/effects";

import {
    LOAD_EMOTION_DEFINITIONS,
    GET_EMOTION_DEFINITIONS,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

// import * as api from "constants/api";
import eq_data from 'data/emotion_definitions.json'

function* getEmotionDefinitions(action) {

  try {
    // const json = yield call(api.GET_EMOTION_DEFINITIONS, action.payload);
    // yield put({ type: GET_EMOTION_DEFINITIONS, payload: json.data });

    yield put({ type: GET_EMOTION_DEFINITIONS, payload: eq_data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* loadEmotionDefinitions() {
  yield takeLatest(LOAD_EMOTION_DEFINITIONS, getEmotionDefinitions);
}



export default function* index() {
  yield all([loadEmotionDefinitions(),
  ]);
}