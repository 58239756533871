import { put } from "redux-saga/effects";
import {
  ALL_ERRORS,
} from "constants/actions";


export function* handleError(e) {
    const errorPayload = {
      status: e.response?.status,
      statusText: e.response?.statusText,
      data: e.response?.data,
    };
    yield put({ type: ALL_ERRORS, payload: errorPayload });
  }