import {
  call,
  put,
  all,
  takeEvery,
  takeLatest,
  throttle,
} from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SURVEY_TOKENS,
  SG_GET_SURVEY_TOKENS,
  DELETE_SURVEY_TOKEN,
  SG_DELETE_SURVEY_TOKEN,
  // SG_ADD_SURVEY_TOKEN,
  // ADD_SURVEY_TOKEN,
  // CLEAR_SURVEY_TOKEN,
  // SG_CLEAR_SURVEY_TOKEN,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadTokens(action) {
  // console.log(action.payload)
  try {
    const json = yield call(api.GET_SURVEY_TOKENS, action.payload);
    yield put({ type: GET_SURVEY_TOKENS, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* tokenLoad() {
  yield takeLatest(SG_GET_SURVEY_TOKENS, loadTokens);
}

function* deleteToken(action) {
  try {
    const json = yield call(api.DELETE_SURVEY_TOKEN, action.payload);
    yield put({ type: DELETE_SURVEY_TOKEN, payload: json.data });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* tokenDelete() {
  yield takeLatest(SG_DELETE_SURVEY_TOKEN, deleteToken);
}

export default function* index() {
  yield all([tokenLoad(), tokenDelete()]);
}
