import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_INDUSTRY_BENCHMARK,
  SG_GET_INDUSTRY_BENCHMARK,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadIndustryBenchmark(action) {
  try {
    const json = yield call(api.GET_INDUSTRY_BENCHMARK, action.payload);

    yield put({ type: GET_INDUSTRY_BENCHMARK, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* industryBenchmarkLoad() {
  yield takeLatest(SG_GET_INDUSTRY_BENCHMARK, loadIndustryBenchmark);
}

export default function* index() {
    yield all([
        industryBenchmarkLoad(),
    ]);
  }
  