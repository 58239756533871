import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_APP_SERVERS, UPDATE_APP_SERVER_PAGE_NUMBER } from "constants/actions";
import { Dimmer, Loader, Card, Pagination, Segment } from 'semantic-ui-react';

const SearchBar = lazy(() => import("./SearchBar"));
const RowCard = lazy(() => import("./RowCard"));

const List = () => {
    const dispatch = useDispatch();
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch({
            type: SG_GET_APP_SERVERS,
            payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ''}`,
        });
        setLoading(true);
    }, [dispatch, page, searchTerm]);

    const { get_app_server } = useSelector(
        (state) => ({
            get_app_server: state.app_server,
        }),
        shallowEqual
    );

    useEffect(() => {
        setResults(get_app_server?.app_server_list?.results);
        setPage(get_app_server?.pageNumber);
        setTotalPages(Math.ceil(get_app_server?.app_server_list?.count / get_app_server?.app_server_list?.page_size));
        if (get_app_server?.app_server_list?.count % get_app_server?.app_server_list?.page_size) {
            setTotalPages(Math.ceil(get_app_server?.app_server_list?.count / get_app_server?.employees?.page_size));
        }
    }, [get_app_server, page]);

    function handlePageChange(event, value) {
        setPage(value.activePage);
        dispatch({
            type: UPDATE_APP_SERVER_PAGE_NUMBER,
            payload: value.activePage,
        });
    }

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer1);
    }, [loading]);

    return (
        <Container>
            <SectionDiv>
                <Suspense fallback={<Loader active inline="centered" />}>
                    <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                </Suspense>
            </SectionDiv>

            <Segment id="results" basic>
                <Dimmer active={loading}>
                    <Loader />
                </Dimmer>
                <Suspense fallback={<Loader active inline="centered" />}>
                    <Card.Group>
                        {results && <RowCard rows={results} />}
                    </Card.Group>
                </Suspense>
            </Segment>

            <SectionDiv>
                <Pagination
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    activePage={page}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </SectionDiv>
        </Container>
    );
};

export default List;

const Container = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionDiv = styled.div`
    padding: 30px 15px;
`;