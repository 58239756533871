import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_REPORT_SERVER_TEST,
  SG_GET_REPORT_SERVER_TEST,
  GET_REPORT_SERVER_RESET_CACHE,
  SG_GET_REPORT_SERVER_RESET_CACHE,
  GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE,
  SG_GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* testMethod(action) {
  try {
    yield call(api.GET_REPORT_SERVER_TEST, action.payload);
    yield put({ type: GET_REPORT_SERVER_TEST, payload: action.payload });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodTest() {
  yield takeLatest(SG_GET_REPORT_SERVER_TEST, testMethod);
}

function* employeeReset(action) {
  try {
    yield call(api.GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE, action.payload);
    yield put({
      type: GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE,
      payload: action.payload,
    });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* resetForEmployee() {
  yield takeLatest(SG_GET_REPORT_SERVER_EMPLOYEE_RESET_CACHE, employeeReset);
}

function* serverReset(action) {
  try {
    yield call(api.GET_REPORT_SERVER_RESET_CACHE, action.payload);
    yield put({ type: GET_REPORT_SERVER_RESET_CACHE, payload: action.payload });
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* resetServer() {
  yield takeLatest(SG_GET_REPORT_SERVER_RESET_CACHE, serverReset);
}

export default function* index() {
  yield all([methodTest(), resetForEmployee(), resetServer()]);
}
