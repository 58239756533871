import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
    // UPDATE_MARKETING_ROI_PAGE_NUMBER,
    GET_MARKETING_ROI_PENDING,
    GET_MARKETING_ROIS,
    SG_GET_MARKETING_ROIS,
    DELETE_MARKETING_ROI,
    SG_DELETE_MARKETING_ROI,
    FETCH_MARKETING_ROI,
    SG_FETCH_MARKETING_ROI,
    EDIT_MARKETING_ROI,
    SG_EDIT_MARKETING_ROI,
    ADD_MARKETING_ROI,
    SG_ADD_MARKETING_ROI,
    // CLEAR_MARKETING_ROI,
    ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
    yield put({ type: GET_MARKETING_ROI_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_MARKETING_ROIS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_MARKETING_ROIS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_MARKETING_ROIS, loadmethods);
}


function* fetchmethods(action) {
    yield put({ type: GET_MARKETING_ROI_PENDING });
  try {
    const json = yield call(api.FETCH_MARKETING_ROI, action.payload);
    yield put({ type: FETCH_MARKETING_ROI, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_MARKETING_ROI, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_MARKETING_ROI, action.payload);
    yield put({ type: EDIT_MARKETING_ROI, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_MARKETING_ROI, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_GPT_MEMBER
  try {
    const json = yield call(api.ADD_MARKETING_ROI, action.payload);
    yield put({ type: ADD_MARKETING_ROI, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_MARKETING_ROI, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_MARKETING_ROI, action.payload);
    yield put({ type: DELETE_MARKETING_ROI, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_MARKETING_ROI, deletemethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
