import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_TEAM_TYPES, 
  SG_GET_TEAM_TYPES,  
  FETCH_TEAM_TYPE, 
  SG_FETCH_TEAM_TYPE, 
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadteamTypes(action) {
  try {
    const json = yield call(api.GET_TEAM_TYPES, action.payload);
    yield put({ type: GET_TEAM_TYPES, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamTypeLoad() {
  yield takeLatest(SG_GET_TEAM_TYPES, loadteamTypes);
}

function* fetchteamTypes(action) {
  try {
    const json = yield call(api.FETCH_TEAM_TYPE, action.payload);
    yield put({ type: FETCH_TEAM_TYPE, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* teamTypeFetch() {
  yield takeLatest(SG_FETCH_TEAM_TYPE, fetchteamTypes);
}


export default function* index() {
  yield all([
    teamTypeLoad(),
    teamTypeFetch(),
  ]);
}
