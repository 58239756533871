import _ from "lodash";
import {
  GET_ERRORS,
  SHOW_ERRORS,
  ALL_ERRORS,
  VALIDATION_ERRORS,
  CLEAR_ERRORS,
  CLEAR_PASSWORD_DATA,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  CONFIRM_RESET_PASSWORD_SUCCESS,
  CONFIRM_RESET_PASSWORD_FAIL,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  msg: {},
  errors: {},
  status: {},
  data: {},
  headers: {},
  fullError: {},
  message: false,
  password: [],
  passwordFail: [],
};

const removeLocalStorage = () => {
  localStorage.removeItem("token");
  // expires_in
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("role_id");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("organization_id");
  localStorage.removeItem("employee_id");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("is_staff");
};

export default function ShowErrors(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        msg: action.payload,
        status: action.payload,
        errors: action.payload,
      };
    case CREATE_MESSAGE:
    case UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case CONFIRM_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        password: action.payload,
      };
    case CONFIRM_RESET_PASSWORD_FAIL:
      return {
        ...state,
        passwordFail: action.payload,
      };
    case ALL_ERRORS:
      const cleanMessage = { ...action.payload };

      // Remove non-serializable parts from the message object
      if (cleanMessage.config) {
        delete cleanMessage.config.transformRequest;
        delete cleanMessage.config.transformResponse;
        delete cleanMessage.request;
      }

      if (cleanMessage?.status === 401) {
        removeLocalStorage();
        return {
          ...state,
          token: null,
          key: null,
          user: null,
          organization_id: null,
          employee_id: null,
          isAuthenticated: false,
          isLoading: false,
          loginerror: null,
          is_staff: null,
          employee: [],
          organization: [],
          status: cleanMessage.status,
          msg: cleanMessage.data,
          fullError: cleanMessage,
        };
      } else if (cleanMessage) {
        return {
          ...state,
          msg: cleanMessage,
          errors: cleanMessage.data,
          status: cleanMessage.status,
          headers: cleanMessage.headers,
          fullError: cleanMessage,
        };
      }

      return {
        ...state,
        status: null,
        data: {},
        headers: {},
        msg: {},
        errors: {},
        fullError: {},
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        msg: {},
        errors: {},
        status: null,
        data: {},
        headers: {},
        fullError: {},
      };
    case CLEAR_PASSWORD_DATA:
    case CLEAR_ALL:
      return {
        ...state,
        msg: {},
        errors: {},
        status: {},
        data: {},
        headers: {},
        fullError: {},
        message: false,
        password: [],
        passwordFail: [],
      };
    default:
      return state;
  }
}