import { call, put, all, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_GLOBAL_SCHEDULE_FREQUENCIES,
    SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
    DELETE_GLOBAL_SCHEDULE_FREQUENCY,
    SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY,
    SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY,
    FETCH_GLOBAL_SCHEDULE_FREQUENCY,
    SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY,
    EDIT_GLOBAL_SCHEDULE_FREQUENCY,
    SG_ADD_GLOBAL_SCHEDULE_FREQUENCY,
    ADD_GLOBAL_SCHEDULE_FREQUENCY,
    CLEAR_GLOBAL_SCHEDULE_FREQUENCY,
    SG_CLEAR_GLOBAL_SCHEDULE_FREQUENCY,
    ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadglobalScheduleFrequencies(action) {
  try {
    const json = yield call(api.GET_GLOBAL_SCHEDULE_FREQUENCIES, action.payload);
    yield put({ type: GET_GLOBAL_SCHEDULE_FREQUENCIES, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* globalScheduleFrequencyLoad() {
  yield takeLatest(SG_GET_GLOBAL_SCHEDULE_FREQUENCIES, loadglobalScheduleFrequencies);
}

function* fetchglobalScheduleFrequency(action) {
  try {
    const json = yield call(api.FETCH_GLOBAL_SCHEDULE_FREQUENCY, action.payload);
    yield put({ type: FETCH_GLOBAL_SCHEDULE_FREQUENCY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* globalScheduleFrequencyFetch() {
  yield takeLatest(SG_FETCH_GLOBAL_SCHEDULE_FREQUENCY, fetchglobalScheduleFrequency);
}

function* updateglobalScheduleFrequency(action) {
  try {
    const json = yield call(api.EDIT_GLOBAL_SCHEDULE_FREQUENCY, action.payload);
    yield put({ type: EDIT_GLOBAL_SCHEDULE_FREQUENCY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* globalScheduleFrequencyUpdate() {
  yield takeEvery(SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY, updateglobalScheduleFrequency);
}

function* addglobalScheduleFrequency(action) {
  try {
    const json = yield call(api.ADD_GLOBAL_SCHEDULE_FREQUENCY, action.payload);
    yield put({ type: ADD_GLOBAL_SCHEDULE_FREQUENCY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* globalScheduleFrequencyAdd() {
  yield takeEvery(SG_ADD_GLOBAL_SCHEDULE_FREQUENCY, addglobalScheduleFrequency);
}

function* deleteglobalScheduleFrequency(action) {
  try {
    yield call(api.DELETE_GLOBAL_SCHEDULE_FREQUENCY, action.payload);
    yield put({ type: DELETE_GLOBAL_SCHEDULE_FREQUENCY, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* globalScheduleFrequencyDelete() {
  yield takeLatest(SG_DELETE_GLOBAL_SCHEDULE_FREQUENCY, deleteglobalScheduleFrequency);
}


export default function* index() {
  yield all([
    globalScheduleFrequencyLoad(),
    globalScheduleFrequencyFetch(),
    globalScheduleFrequencyUpdate(),
    globalScheduleFrequencyAdd(),
    globalScheduleFrequencyDelete(),
  ]);
}
