import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTION_SELECT,
  SG_GET_SURVEY_QUESTION_SELECT,
  GET_ALL_SURVEY_QUESTION_SELECT,
  SG_GET_ALL_SURVEY_QUESTION_SELECT,
  FETCH_SURVEY_QUESTION,
  SG_FETCH_SURVEY_QUESTION,
  EDIT_SURVEY_QUESTION,
  SG_EDIT_SURVEY_QUESTION,
  ADD_SURVEY_QUESTION,
  SG_ADD_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  SG_DELETE_SURVEY_QUESTION,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
  //   yield put({ type: GET_methodS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_SURVEY_QUESTIONS, action.payload);
    yield put({ type: GET_SURVEY_QUESTIONS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_SURVEY_QUESTIONS, loadmethods);
}

function* loadSelectmethods(action) {
  try {
    const json = yield call(api.GET_SURVEY_QUESTION_SELECT, action.payload);
    yield put({ type: GET_SURVEY_QUESTION_SELECT, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodSelectLoad() {
  yield takeLatest(SG_GET_SURVEY_QUESTION_SELECT, loadSelectmethods);
}


function* loadAllSelectmethods(action) {
  try {
    const json = yield call(api.GET_ALL_SURVEY_QUESTION_SELECT, action.payload);
    yield put({ type: GET_ALL_SURVEY_QUESTION_SELECT, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodSelectAllLoad() {
  yield takeLatest(SG_GET_ALL_SURVEY_QUESTION_SELECT, loadAllSelectmethods);
}

function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_SURVEY_QUESTION, action.payload);
    yield put({ type: FETCH_SURVEY_QUESTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_SURVEY_QUESTION, fetchmethods);
}

function* updatemethods(action) {
  try {
    const json = yield call(api.EDIT_SURVEY_QUESTION, action.payload);
    yield put({ type: EDIT_SURVEY_QUESTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_SURVEY_QUESTION, updatemethods);
}

function* addmethods(action) {
  try {
    const json = yield call(api.ADD_SURVEY_QUESTION, action.payload);
    yield put({ type: ADD_SURVEY_QUESTION, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_SURVEY_QUESTION, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_SURVEY_QUESTION, action.payload);
    yield put({ type: DELETE_SURVEY_QUESTION, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_SURVEY_QUESTION, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodSelectLoad(),
    methodSelectAllLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
