import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { SG_FETCH_USER_EMPLOYEE, CLEAR_PASSWORD_DATA } from "constants/actions";
import { DomainContext } from 'layouts/LayoutContext';
import { DomainList } from "data/domain";
import SideBar from "./SideBar";
import MobileNav from "./MobileNav";
import TopBar from "./TopBar";
import Help from "./Help";
import ErrorWrapper from "error/index";
import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const ComplianceCheck = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [employeeId, setEmployeeId] = useState(false);
  const [employee, setEmployee] = useState(false);

  const { get_auth, get_employees, get_error } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
    }),
    shallowEqual
  );

  console.log("get_auth", get_auth); 
  console.log("get_error", get_error);

  const IsStaff = useSelector((state) => state.auth?.user?.is_staff, shallowEqual);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!IsStaff) {
        navigate('/app/switch-org');
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [IsStaff, navigate]);

  useEffect(() => {
    if (get_auth?.token) {
      setEmployeeId(get_auth.employee_id);
    }
  }, [get_auth]);

  useEffect(() => {
    if (get_auth?.token) {
      setEmployeeId(get_auth.employee_id);
    }
  }, [get_auth]);

  useEffect(() => {
    if (!employee && Number(employeeId) > 0) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: { id: employeeId },
      });
    }
  }, [dispatch, employeeId, employee]);

  useEffect(() => {
    if (get_auth?.token && employeeId) {
      setEmployee(get_employees?.userEmp);
    }
  }, [dispatch, get_employees, employeeId, get_auth]);

  useEffect(() => {
    if (!get_auth?.token) {
      navigate("/account/login", { state: { from: location } });
    }
  }, [get_auth, navigate, location]);

  useEffect(() => {
    if (employee?.email_status === 9 && location.pathname !== "/app/dashboard") {
      navigate("/app/dashboard");
    }
  }, [employee, location, navigate]);

  // Handle 401 Unauthorized error
  useEffect(() => {
    if (get_error?.status === 401) {
      dispatch({ type: CLEAR_PASSWORD_DATA });
      navigate("/account/login", { state: { from: location } });
    }
  }, [get_error, dispatch, navigate, location]);

  return null;
};

const MobileContainer = () => {
  return (
    <>
      <Media greaterThan="mobile" style={{ position: "relative", display: "flex", alignItems: "center" }}>
        <SideBar />
        <TopBar />
        <div style={{ width: "100vw", display: "flex", justifyContent: "center", position: "relative" }}>
          <div style={{ maxWidth: 1400, width: "100vw", paddingLeft: 68, paddingTop: 37, paddingRight: 30 }}>
            <ErrorWrapper>
              <Outlet />
            </ErrorWrapper>
          </div>
        </div>
      </Media>
      <Media at="mobile" style={{ position: "relative" }}>
        <MobileNav />
        <div style={{ width: "100%", marginTop: "100px", maxWidth: "100%", overflowX: "hidden", padding: 10 }}>
          <Outlet />
        </div>
        <Help />
      </Media>
    </>
  );
};

const ResponsiveContainer = ({ children }) => {
  const domain = window.location.host;
  return (
    <MediaContextProvider>
      <DomainContext.Provider value={DomainList?.[domain] ? DomainList?.[domain] : Object.values(DomainList)[0]}>
        <ComplianceCheck />
        <MobileContainer>{children}</MobileContainer>
      </DomainContext.Provider>
    </MediaContextProvider>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;