import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
  GET_GPT_PROCESS_PENDING,
  POST_GPT_PROCESS,
  SG_POST_GPT_PROCESS,
    ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* processMethods(action) {
    yield put({ type: GET_GPT_PROCESS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.POST_GPT_PROCESS, action.payload);
    // console.log(json.data)
    yield put({ type: POST_GPT_PROCESS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodProcess() {
  yield takeEvery(SG_POST_GPT_PROCESS, processMethods);
}


export default function* index() {
  yield all([
    methodProcess(),
  ]);
}
