import React, { useState, useEffect, Suspense, lazy } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_FETCH_APP_SERVER_LOG } from "constants/actions";
import { Icon, Header, List, Dimmer, Loader } from "semantic-ui-react";
import { format } from "date-fns";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const ReactJson = lazy(() => import("react-json-view"));
const ClickToCopy = lazy(() => import("utilities/ClickToCopy"));
const LogList = lazy(() => import("appserver/log/List"));
const CreateLog = lazy(() => import("appserver/log/Create"));

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    name: null,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTemplateId(id);
  }, [id]);

  const { get_app_server_log } = useSelector(
    (state) => ({
      get_app_server_log: state.app_server_log,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (templateId) {
      dispatch({
        type: SG_FETCH_APP_SERVER_LOG,
        payload: { id: templateId },
      });
      setLoading(true);
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    if (get_app_server_log?.[templateId]) {
      setTemplateDetails(get_app_server_log?.[templateId]);
    }
    setLoading(get_app_server_log?.pending);
  }, [get_app_server_log, templateId]);

  function BackArrow() {
    navigate(`/app/app-server/details/${templateDetails?.app_server}`);
  }

  return (
    <>
      <Header as="h2">
        <Icon
          style={{ cursor: "pointer" }}
          onClick={BackArrow}
          name="arrow left"
        />
        {templateId && `More Details of ${templateDetails?.server_name} # ${templateDetails?.server_ip}`}
      </Header>

      <SectionDiv>
        <BasicErrorMessage />
      </SectionDiv>
      {templateId && (
        <>
          <SectionDiv>
            <Container>
              <List horizontal>
                <ListItem label="Id" value={templateDetails?.id} />
                <ListItem
                  label="Created"
                  value={
                    templateDetails?.created_at &&
                    format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")
                  }
                />
                <ListItem
                  label="Updated"
                  value={
                    templateDetails?.updated_at &&
                    format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")
                  }
                />
                <ListItem label="Name" value={templateDetails?.employee_full_name} />
                <ListItem label="Url" value={templateDetails?.server_url} />
                <ListItem label="Update Type" value={`${templateDetails?.update_type} # ${templateDetails?.update_type_text}`} />
              </List>
            </Container>
          </SectionDiv>

          <SectionDiv>
            {templateDetails?.details ? (
              <Suspense fallback={<Loader active inline="centered" />}>
                <ReactJson src={templateDetails?.details} />
              </Suspense>
            ) : (
              <p>Nothing generated yet</p>
            )}
          </SectionDiv>
        </>
      )}

      <Dimmer.Dimmable blurring dimmed={loading}>
        <Dimmer active={loading}>
          <Loader />
          <Header as="h2" icon inverted>
            Loading, Please wait.
          </Header>
        </Dimmer>
      </Dimmer.Dimmable>

    </>
  );
};

export default Details;

const ListItem = ({ label, value }) => {
  return (
    <List.Item>
      <List.Content>
        <ListContainer>
          <List.Header>{label}</List.Header>
          {value}
        </ListContainer>
      </List.Content>
    </List.Item>
  );
};

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;

const Container = styled.div`
  display: flex;
`;

const LabelHead = styled.label`
  font-weight: 700;
  display: block;
`;

const ListContainer = styled.div`
  margin: 0px 25px;
`;

const TextHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
`;