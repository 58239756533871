import { call, put, all, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_SIGNUP_TOKENS,
    SG_GET_SIGNUP_TOKENS,
    DELETE_SIGNUP_TOKEN,
    SG_DELETE_SIGNUP_TOKEN,
    // SG_ADD_SIGNUP_TOKEN,
    // ADD_SIGNUP_TOKEN,
    // CLEAR_SIGNUP_TOKEN,
    // SG_CLEAR_SIGNUP_TOKEN,
    ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadTokens(action) {
  try {
    const json = yield call(api.GET_SIGNUP_TOKENS, action.payload);
    yield put({ type: GET_SIGNUP_TOKENS, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* tokenLoad() {
  yield takeLatest(SG_GET_SIGNUP_TOKENS, loadTokens);
}


function* deleteToken(action) {
  try {
    const json = yield call(api.DELETE_SIGNUP_TOKEN, action.payload);
    yield put({ type: DELETE_SIGNUP_TOKEN, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* tokenDelete() {
  yield takeLatest(SG_DELETE_SIGNUP_TOKEN, deleteToken);
}


export default function* index() {
  yield all([
    tokenLoad(),
    tokenDelete(),
  ]);
}
