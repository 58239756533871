import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, Button, Message, Modal, Header, Input } from "semantic-ui-react";

import { useParams } from "react-router";

import NewEditCategory from "../AddEdit/NewEditCategory";
import Summary from "./Summary";
import ListJson from "./ListJson";
import SearchInfo from "./SearchInfo";
import EditJson from "categories/EditJson/EditJson";
import AddNewJson from "categories/EditJson/AddNew";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_EDIT_SURVEY_STRUCTURE,
  SG_FETCH_SURVEY_STRUCTURE,
  PULL_ORGANIZATION,
  SG_CSV_FILE_SURVEY_STRUCTURE,
  CSV_FILE_SURVEY_STRUCTURE,
} from "constants/actions";

import ViewJson from "./viewJson";

const Categories = ({ setOrgName }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [organizationId, setOrganizationId] = useState(false);

  const [surveyStruc, setSurveyStruc] = useState({});
  const [surveyStrucRow, setSurveyStrucRow] = useState([]);
  const [rowId, setRowId] = useState(0);
  const [openNewCategory, setOpenNewCategory] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const [surveyStructureId, setSurveyStructureId] = useState(false);

  const [openSummary, setOpenSummary] = useState(false);
  const [openNameEdit, setOpenNameEdit] = useState(false);
  const [openNameChanged, setOpenNameChanged] = useState(false);

  const [openEditJson, setOpenEditJson] = useState(false);
  const [openViewSummary, setOpenViewSummary] = useState(false);
  const [updateJsonFile, setUpdateJsonFile] = useState(0);
  const [savedSuccessFully, setSavedSuccessFully] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvDownloadFile, setCsvDownloadFile] = useState(false);

  const [openNewJson, setOpenNewJson] = useState(false);

  const { get_survey_structure, get_organizations } = useSelector(
    (state) => ({
      get_organizations: state.organizations,
      get_survey_structure: state.surveystructure,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (Number(id) > 0) {
      dispatch({
        type: SG_FETCH_SURVEY_STRUCTURE,
        payload: { id },
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationId },
      });
    }
  }, [dispatch, organizationId]);

  function SaveSurveyStructure() {
    if (surveyStructureId) {
      setLoading(true);
      setUpdateJsonFile(0);
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          categories: surveyStrucRow,
        },
      });
    }
  }

  function RegenerateCsvStructure() {
    if (surveyStructureId) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          regenerate_csv: "yes",
        },
      });
    }
  }

  function GenerateCsvFile() {
    if (surveyStructureId) {
      dispatch({
        type: SG_CSV_FILE_SURVEY_STRUCTURE,
        payload: {
          survey_structure_id: surveyStructureId,
        },
      });
    }
  }

  useEffect(() => {
    if (get_survey_structure) {
      setCsvDownloadFile(get_survey_structure?.csv_file?.download_csv_link);
    }
  }, [get_survey_structure, dispatch]);

  function Downloadcsv() {
    if (csvDownloadFile) {
      window.open(csvDownloadFile, "_blank");
      dispatch({ type: CSV_FILE_SURVEY_STRUCTURE, payload: null }); // Reset the download state
    }
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_survey_structure?.pending);
      if (updateJsonFile > 0 && get_survey_structure?.pending === false) {
        setSavedSuccessFully(true);
      }
    }, 1000);
    return () => clearTimeout(timer1);
  }, [get_survey_structure, updateJsonFile]);

  useEffect(() => {
    if (id) {
      setSurveyStrucRow(get_survey_structure?.[id]?.categories);
      setSurveyStructureId(id);
      setOpenNameChanged(get_survey_structure?.[id]?.name);
      setOrganizationId(get_survey_structure?.[id]?.organization);
    }
  }, [get_survey_structure, id]);

  useEffect(() => {
    setOrgName(get_organizations?.[organizationId]?.name);
  }, [get_organizations, organizationId, setOrgName]);

  console.log("surveyStrucRow", surveyStrucRow);
  useEffect(() => {
    if (surveyStrucRow) {
      const ErrorMessage = [];
      let PrimaryFound = false;
      let SecondaryFound = false;
      surveyStrucRow.map((row) => {
        if (row.priority === "primary" && !PrimaryFound) {
          PrimaryFound = true;
        }
        if (row.priority === "secondary" && !SecondaryFound) {
          SecondaryFound = true;
        }
        return null;
      });

      if (!PrimaryFound) {
        ErrorMessage.push(
          "No row with Priority Primary Found, atleast one row with Priority Primary is essenstial"
        );
      }
      if (!SecondaryFound) {
        ErrorMessage.push(
          "No row with Priority Secondary Found, atleast one row with Priority Secondary is essenstial"
        );
      }
      setErrorList(ErrorMessage);
    }
  }, [surveyStrucRow]);

  function EditName() {
    // console.log(openNameChanged)
    if (surveyStructureId && openNameChanged?.length > 3) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          name: openNameChanged,
        },
      });
      setOpenNameEdit(false);
    }
  }

  function AddNewCategory() {
    setOpenNewCategory(true);
    setRowId(0);
    setSurveyStruc({});
  }

  return (
    <Container>
      <Message
        color="orange"
        icon="warning sign"
        header="Do not Modify before you read this"
        content={`This data is crucial for the entire survey, please 
          donot change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />
      {errorList && errorList.length > 0 && (
        <Message floating color="red">
          <Message.Header>Errors</Message.Header>
          <Message.List items={errorList} />
        </Message>
      )}
      {updateJsonFile > 0 && (
        <Message
          color="red"
          icon="folder open"
          header="NOT SAVED"
          content={`Please click on the orange button below to save the changes.
       `}
        />
      )}
      {updateJsonFile === 0 && savedSuccessFully && (
        <Message
          color="green"
          icon="save outline"
          header="Saved Successfully"
          content={`The contents were saved sucessfully.
        `}
        />
      )}

      {openNameEdit ? (
        <CenterContent>
          <Input
            placeholder="Category Name"
            name="name"
            defaultValue={openNameChanged}
            // value={get_survey_structure?.[0]?.name}
            onChange={(v) => setOpenNameChanged(v.target.value)}
          />
          <Button circular color="green" icon="check" onClick={EditName} />
          <Button
            circular
            color="red"
            icon="close"
            onClick={() => setOpenNameEdit(false)}
          />
        </CenterContent>
      ) : (
        <Header as="h3" icon textAlign="center">
          <Icon name="file alternate outline" circular />
          <Header.Content onClick={() => setOpenNameEdit(true)}>
            {openNameChanged}
          </Header.Content>
        </Header>
      )}
      <NewEditCategory
        surveyStruc={surveyStruc}
        setSurveyStruc={setSurveyStruc}
        rowId={rowId}
        surveyStrucRow={surveyStrucRow}
        setSurveyStrucRow={setSurveyStrucRow}
        openNewCategory={openNewCategory}
        organizationId={organizationId}
        surveyStructureId={surveyStructureId}
        setOpenNewCategory={setOpenNewCategory}
        setUpdateJsonFile={setUpdateJsonFile}
        setLoading={setLoading}
      />

      <ButtonContainer>
        <StyledButton onClick={AddNewCategory} color="#0000ff">
          + New Category
        </StyledButton>
        <StyledButton onClick={() => setOpenNewJson(true)} color="#ff0000">
          Replace or Add New JSON
        </StyledButton>
      </ButtonContainer>

      <SearchInfo surveyStrucRow={surveyStrucRow} />

      <ListJson
        surveyStrucRow={surveyStrucRow}
        setSurveyStrucRow={setSurveyStrucRow}
        surveyStructureId={surveyStructureId}
        setRowId={setRowId}
        setSurveyStruc={setSurveyStruc}
        setOpenNewCategory={setOpenNewCategory}
        setUpdateJsonFile={setUpdateJsonFile}
        loading={loading}
        setLoading={setLoading}
      />

      <ViewJson details={get_survey_structure} />

      <Button
        style={{ margin: "20px 20px" }}
        icon
        labelPosition="left"
        floated="left"
        onClick={() => setOpenViewSummary(true)}
        size="small"
        color="green"
      >
        View as JSON Summary
        <Icon name="eye" />
      </Button>

      <Button
        style={{ margin: "20px 0px" }}
        icon
        labelPosition="left"
        floated="left"
        onClick={() => setOpenSummary(true)}
        size="small"
        color="green"
      >
        View Summary
        <Icon name="eye" />
      </Button>

      <Button
        style={{ margin: "20px 0px" }}
        icon
        labelPosition="right"
        disabled={updateJsonFile === 0 ? true : false}
        floated="right"
        onClick={() => SaveSurveyStructure()}
        size="small"
        color={updateJsonFile === 0 ? "violet" : "orange"}
      >
        Save
        <Icon name="save" />
      </Button>

      {/* <Button
        style={{ margin: "20px 20px" }}
        icon
        labelPosition="right"
        floated="right"
        onClick={() => setOpenEditJson(true)}
        size="small"
        color="violet"
      >
        Edit JSON Values
        <Icon name="save" />
      </Button> */}

      <Button
        style={{ margin: "20px 20px" }}
        icon
        labelPosition="right"
        floated="right"
        onClick={() => setOpenEditJson(true)}
        size="small"
        color="violet"
      >
        Edit JSON
        <Icon name="save" />
      </Button>

      {csvDownloadFile ? (
        <Button
          style={{ margin: "20px 20px" }}
          icon
          labelPosition="right"
          floated="right"
          onClick={Downloadcsv}
          size="small"
          color="blue"
        >
          Download CSV
          <Icon name="cloud download" />
        </Button>
      ) : (
        <Button
          style={{ margin: "20px 20px" }}
          icon
          labelPosition="right"
          floated="right"
          onClick={GenerateCsvFile}
          size="small"
          color="violet"
        >
          Download CSV
          <Icon name="cloud download" />
        </Button>
      )}

      <Button
        style={{ margin: "20px 20px" }}
        icon
        labelPosition="right"
        floated="right"
        onClick={RegenerateCsvStructure}
        size="small"
        color="orange"
      >
        Update CSV (in app)
        <Icon name="save" />
      </Button>

      <EditJson
        surveyStructureId={surveyStructureId}
        openEditJson={openEditJson}
        setOpenEditJson={setOpenEditJson}
        openViewSummary={openViewSummary}
        setOpenViewSummary={setOpenViewSummary}
      />

      <AddNewJson
        surveyStructureId={surveyStructureId}
        openNewJson={openNewJson}
        setOpenNewJson={setOpenNewJson}
        openViewSummary={openViewSummary}
        setOpenViewSummary={setOpenViewSummary}
      />
      <Modal open={openSummary} onClose={() => setOpenSummary(false)}>
        <Modal.Header>
          <Header as="h1">
            Category Summary
            <Header.Subheader>
              Detailed Summary of JSON file, this is how the information gets
              processed
            </Header.Subheader>
          </Header>
        </Modal.Header>

        <Modal.Content scrolling>
          <Modal.Description>
            <Summary
              surveyStrucRow={surveyStrucRow}
              surveyStructure={get_survey_structure}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenSummary(false)} color="violet">
            Close <Icon name="close" />
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0px 30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px;
    margin-top: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid ${(props) => props.color};
    color: ${(props) => props.color};
    margin-right: 3px;
  }

  background-color: ${(props) => props.color};
`;

const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
