import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_SURVEY_AI_LOG_PENDING,
  GET_SURVEY_AI_LOG,
  SG_GET_SURVEY_AI_LOG,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";


function* loadmethods(action) {
  yield put({ type: GET_SURVEY_AI_LOG_PENDING });
  try {
    const json = yield call(api.GET_SURVEY_AI_LOG, action.payload);
    yield put({ type: GET_SURVEY_AI_LOG, payload: json.data });
  } catch (e) {

    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_SURVEY_AI_LOG, loadmethods);
}

export default function* index() {
  yield all([methodLoad()]);
}
