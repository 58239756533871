import React, { useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { LOGIN_REQUEST, CLEAR_ERRORS } from "constants/actions";
import { Domain } from "WebConfig";
import Input from "./InputLogin";
import UpgradePassword from "./UpgradePassword";
import { ValidateEmail } from "utilities/Validate";
import UserErrorMessages from "./UserErrorMessages";
import { Message } from "semantic-ui-react";
import SelectOrg from "./SelectOrg";
import GoogleAuth from "./GoogleAuth";
import { DomainContext } from 'layouts/LayoutContext';

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const Domain = useContext(DomainContext);
  let [searchParams, setSearchParams] = useSearchParams();
  let logo = searchParams.get("org");
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [userAuth, setUserAuth] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [showOrgs, setShowOrgs] = useState(false);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    setUserAuth(get_auth);
  }, [get_auth]);

  useEffect(() => {
    setPasswordReset(get_auth?.reset_password);
  }, [get_auth?.reset_password]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (password?.length === 0) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter a password",
      };
      totalErrors += 1;
    }
    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email",
        };
        totalErrors += 1;
      }
    }

    ErrorList['errors'] = totalErrors;
    setFormErrors(ErrorList);
  }, [password, email, orgList, selectedOrg, pristine]);

  function emailValue(e) {
    if (e) {
      setEmail(e);
    }
  }

  function passwordValue(e) {
    if (e) {
      setPassword(e);
    }
  }

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: email,
        password: password,
        organization_id: selectedOrg?.ID ? selectedOrg.ID : 0,
      },
    });
  };

  if (userAuth?.next_steps === 2) {
    return <UpgradePassword />;
  }

  if (userAuth?.token) {
    const from = location.state?.from?.pathname || "/app/dashboard";
    return <Navigate to={from} />;
  }

  const today = new Date();

  return (
    <Container>
      <FormContainer>
        <Box>
          <IMG src={Domain.fullImage} />
          <Title>{Domain.loginText}</Title>
          <UserErrorMessages />
          {formErrors.length > 0 && !pristine && (
            <Message
              negative
              header="We need these details!"
              list={formErrors}
            />
          )}
          {passwordReset?.message && (
            <Message success content={passwordReset?.message} />
          )}
          <Input
            placeholder="Email"
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={emailValue}
          />
          {formErrors.email?.error && !pristine && (
            <Message error content={formErrors.email?.message} />
          )}
          <Input
            placeholder="Password"
            name="password"
            type="password"
            id="password"
            value={password}
            onChange={passwordValue}
          />
          {formErrors.password?.error && !pristine && (
            <Message error content={formErrors.password?.message} />
          )}
          <Button onClick={onSubmit} id="sign-in">
            Sign In
          </Button>
          <Bottom>
            <div>
              <Link to={logo ? "/account/forgot-password?org=" + logo : "/account/forgot-password"}>
                Forgot Password?
              </Link>
            </div>
            <div>
              {Domain.allowRegister ? (
                <>
                  {Domain.registerText}{" "}
                  <a href="https://app.innerlogic.com/account/register" target="_blank" rel="noreferrer">
                    Sign Up
                  </a>
                </>
              ) : (
                <p>{Domain.notRegisterText}</p>
              )}
            </div>
          </Bottom>
          <Bottom>
            <GoogleAuth login={true} />
          </Bottom>
          {Domain.allowRegister && (
            <Banner>
              To know more about us{" "}
              <a href={Domain.homePage} target="_blank" rel="noreferrer">
                click here
              </a>
            </Banner>
          )}
          <Banner>
            To learn more about how we collect, use, disclose, transfer, and store your information,
            please visit our{" "}
            <a href="https://innerlogic.com/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
          </Banner>
          <LinkContainer>
            <p>Copyright © {today.getFullYear()} innerlogic.com</p>
            <a href="https://innerlogic.com/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy.
            </a>
            <a href=" https://innerlogic.com/terms/ " target="_blank" rel="noreferrer">
              Terms of Use
            </a>
          </LinkContainer>
        </Box>
      </FormContainer>
    </Container>
  );
};

export default LoginForm;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 160px;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;

const Banner = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`;

const LinkContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;
  a {
    color: #2d50e2;
    font-weight: bold;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const SideImg = styled.img`
  min-height: 100%;
`;

const ImgContainer = styled.div`
  width: calc(100% - 500px);
  max-width: 900px;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 100px 30px 0 30px;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;