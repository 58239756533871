import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "constants/api";


import {
  GET_LOGIN_HISTORY_PENDING,
  GET_LOGIN_HISTORY,
  SG_GET_LOGIN_HISTORY,
  DELETE_LOGIN_HISTORY,
  SG_DELETE_LOGIN_HISTORY,
  FETCH_LOGIN_HISTORY,
  SG_FETCH_LOGIN_HISTORY,
  EDIT_LOGIN_HISTORY,
  SG_EDIT_LOGIN_HISTORY,
  ADD_LOGIN_HISTORY,
  SG_ADD_LOGIN_HISTORY,
  ALL_ERRORS,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadmethods(action) {
    yield put({ type: GET_LOGIN_HISTORY_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_LOGIN_HISTORY, action.payload);
    yield put({ type: GET_LOGIN_HISTORY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_LOGIN_HISTORY, loadmethods);
}

function* fetchmethods(action) {
  yield put({ type: GET_LOGIN_HISTORY_PENDING });
  try {
    const json = yield call(api.FETCH_LOGIN_HISTORY, action.payload);
    yield put({ type: FETCH_LOGIN_HISTORY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_LOGIN_HISTORY, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_LOGIN_HISTORY_PENDING });
  try {
    const json = yield call(api.EDIT_LOGIN_HISTORY, action.payload);
    yield put({ type: EDIT_LOGIN_HISTORY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodUpdate() {
  yield takeEvery(SG_EDIT_LOGIN_HISTORY, updatemethods);
}

function* addmethods(action) {
  yield put({ type: GET_LOGIN_HISTORY_PENDING });
  try {
    const json = yield call(api.ADD_LOGIN_HISTORY, action.payload);       
    yield put({ type: ADD_LOGIN_HISTORY, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_LOGIN_HISTORY, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_LOGIN_HISTORY_PENDING });
  try {
    yield call(api.DELETE_LOGIN_HISTORY, action.payload);
    yield put({ type: DELETE_LOGIN_HISTORY, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_LOGIN_HISTORY, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
