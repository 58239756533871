import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_ONBOARDINGS,
    SG_GET_ONBOARDINGS,
    DELETE_ONBOARDING,
    SG_DELETE_ONBOARDING,
    SG_ADD_ONBOARDING,
    ADD_ONBOARDING,
    SG_FETCH_ONBOARDING,
    FETCH_ONBOARDING,
    SG_EDIT_ONBOARDING,
    EDIT_ONBOARDING,
    CLEAR_ONBOARDING,
    SG_CLEAR_ONBOARDING,
    ALL_ERRORS,
    SHOW_ERRORS,
    SIGNUP_HOME,
} from "constants/actions";
import { handleError } from "./ApiError";

function* loadonboardings(action) {
  // console.log("i was here", action)
  try {
    const json = yield call(api.GET_ONBOARDINGS, action.payload);
    // console.log("i was here", json)
    yield put({ type: GET_ONBOARDINGS, payload: json.data });
    
  } catch (e) {
    // console.log("i was here", e.message)
    // yield put({ type: SIGNUP_HOME, payload: e.message });
    // if (e.response) {
    //   // console.log(e.response.data);
    //   // console.log(e.response.status);
    //   // console.log(e.response.headers);
    // }
    yield call(handleError, e);
  }
}

export function* onboardingLoad() {
  yield takeLatest(SG_GET_ONBOARDINGS, loadonboardings);
}

function* fetchonboardings(action) {

  try {
    const json = yield call(api.FETCH_ONBOARDING, action.payload);
    yield put({ type: FETCH_ONBOARDING, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* onboardingFetch() {
  yield takeLatest(SG_FETCH_ONBOARDING, fetchonboardings);
}

function* updateonboardings(action) {
  try {
    const json = yield call(api.EDIT_ONBOARDING, action.payload);
    // console.log(json.data)
    yield put({ type: EDIT_ONBOARDING, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* onboardingUpdate() {
  yield takeLatest(SG_EDIT_ONBOARDING, updateonboardings);
}

function* addonboardings(action) {
  try {
    const json = yield call(api.ADD_ONBOARDING, action.payload);
    yield put({ type: ADD_ONBOARDING, payload: json.data });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* onboardingAdd() {
  yield takeLatest(SG_ADD_ONBOARDING, addonboardings);
}


function* deleteonboardings(action) {
  try {
    yield call(api.DELETE_ONBOARDING, action.payload);
    yield put({ type: DELETE_ONBOARDING, payload: action.payload });

  } catch (e) {
    yield call(handleError, e);
  }
}

export function* onboardingDelete() {
  yield takeLatest(SG_DELETE_ONBOARDING, deleteonboardings);
}


export default function* index() {
  yield all([
    onboardingLoad(),
    onboardingFetch(),
    onboardingUpdate(),
    onboardingAdd(),
    onboardingDelete(),
  ]);
}
