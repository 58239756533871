import React, { useEffect, useState } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Domain, Environment } from 'WebConfig';
import { CLEAR_ALL, LOGOUT_REQUEST } from 'constants/actions';

const Logout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
    }),
    shallowEqual
  );

  const [success] = useState('Logged Out Successfully, taking you to website');

  useEffect(() => {
    if (get_auth?.token) {
      dispatch({ type: LOGOUT_REQUEST });
    }
    dispatch({ type: CLEAR_ALL });
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (Environment === 'Development') {
      setTimeout(() => navigate('/account/login'), 500);
    } else {
      setTimeout(() => (window.location = Domain.homePage), 3000);
    }
  }, [navigate]);

  return (
    <div style={{ height: '100%', paddingTop: '25vh' }}>
      {success && <Loader active inline="centered" />}

      <div
        style={{
          height: '50%',
          width: '50%',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        {success && (
          <>
            <Loader />
            <Message success content={success} />
          </>
        )}
      </div>
    </div>
  );
};

export default Logout;