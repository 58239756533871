import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_EDIT_PRODUCT_PACKAGE,
  SG_GET_PRODUCT_SELECT,
} from "constants/actions";
import { Button, Input } from "semantic-ui-react";
import Select from "react-select";

const Details = ({ templateId, templateDetails }) => {
  const dispatch = useDispatch();
  const [productSelected, setProductSelected] = useState();
  const [productSelectList, setProductSelectList] = useState([]);

  const { get_product_select } = useSelector(
    (state) => ({
      get_product_select: state.product.select_list,
    }),
    shallowEqual
  );

  const [productList, setProductList] = useState(
    templateDetails?.product_list || {}
  );

  useEffect(() => {
    setProductList(templateDetails?.product_list);
  }, [templateDetails?.product_list]);

  const handleAddProduct = () => {
    if (!productSelected || !productSelected.sort_order) return;

    const updatedList = {
      ...productList,
      [productSelected.sort_order]: productSelected,
    };

    setProductList(updatedList);
  };

  const handleDeleteProduct = (sortOrder) => {
    const { [sortOrder]: _, ...updatedList } = productList;
    setProductList(updatedList);
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_PRODUCT_SELECT,
      payload: "type=regular",
    });
  }, [dispatch]);

  const handleSubmit = () => {
    const payload = { product_list: productList };
    if (templateId) {
      dispatch({
        type: SG_EDIT_PRODUCT_PACKAGE,
        payload: { id: templateId, ...payload },
      });
    }
  };

  return (
    <>
      <FormColumn>
        {/* Display the productList */}
        <FormSection>
          <LabelHead>Products with versions in Package</LabelHead>
          {productList &&
            Object.keys(productList).map((sortOrder) => (
              <ProductItem key={sortOrder}>
                <StyledInput
                  value={`${productList[sortOrder]?.name} (${
                    productList[sortOrder]?.sort_order
                  }) ${productList[sortOrder]?.version || ""} ${
                    productList[sortOrder].product_stage
                  }`}
                  readOnly
                />
                <Button
                  color="red"
                  icon="delete"
                  onClick={() => handleDeleteProduct(sortOrder)}
                />
              </ProductItem>
            ))}
        </FormSection>
      </FormColumn>

      {/* Product Select and Add Button in One Line */}
      <FormSection>
        <LabelHead>Product List</LabelHead>
        <InlineGroup>
          <StyledSelect
            name="product_group"
            options={get_product_select}
            onChange={(e) => setProductSelected(e)}
            getOptionLabel={(option) =>
              `${option.name} ${option.version} ${option.product_stage}`
            }
            getOptionValue={(option) => `${option.id}`}
            value={productSelected}
          />

          <Button onClick={handleAddProduct}>Add Product</Button>
        </InlineGroup>
      </FormSection>

      <SectionDiv lastSection={true}>
        <Button floated="right" color="green" onClick={handleSubmit}>
          {templateId && "Update Product List"}
        </Button>
      </SectionDiv>
    </>
  );
};

export default Details;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const InlineGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

// Set minimum width for the select component
const StyledSelect = styled(Select)`
  min-width: 500px; /* Set a minimum width for the select dropdown */
`;

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SectionDiv = styled.div`
  padding: 10px 0;
  margin: 10px 0;
  margin-bottom: ${(props) => (props.lastSection === true ? "100px" : "20px")};
`;
