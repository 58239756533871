import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_PROD_ORG_CONTRACT,
  RESET_PROD_ORG_CONTRACT,
} from "constants/actions";
import { Input, Header, Icon } from "semantic-ui-react";
import { format } from "date-fns";

const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);

  useEffect(() => {
    setTemplateId(id);
    dispatch({ type: RESET_PROD_ORG_CONTRACT });
    if (id) {
      dispatch({ type: SG_FETCH_PROD_ORG_CONTRACT, payload: { id } });
    }
  }, [dispatch, id]);

  const { get_prod_org_contract } = useSelector(
    (state) => ({ get_prod_org_contract: state.prod_org_contract }),
    shallowEqual
  );

  useEffect(() => {
    if (get_prod_org_contract && templateId) {
      setTemplateDetails(get_prod_org_contract[templateId]);
    }
  }, [get_prod_org_contract, templateId]);

  const [productList, setProductList] = useState(
    templateDetails?.product ? Object.entries(templateDetails.product).map(([id, details]) => ({ id, ...details })) : []
  );

  useEffect(() => {
    setProductList(
      templateDetails?.product ? Object.entries(templateDetails.product).map(([id, details]) => ({ id, ...details })) : []
    );
  }, [templateDetails?.product]);

  const BackArrow = () => navigate(`/app/product`);

  console.log("productList", productList);
  return (
    <>
      <Header as="h2">
        <StyledIcon name="arrow left" onClick={BackArrow} />
        Details of Contract
      </Header>
      <DetailRow label="Created">
        {templateDetails?.created_at &&
          format(new Date(templateDetails?.created_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Organization">
        {templateDetails?.organization} - {templateDetails?.organization_name}
      </DetailRow>

      <DetailRow label="Price">
        {templateDetails?.price} - {templateDetails?.price_name}
      </DetailRow>
      <DetailRow label="Start Date">
        {templateDetails?.start_date &&
          format(
            new Date(templateDetails?.start_date + "T00:00:00"),
            "dd-MMM-yyyy"
          )}
      </DetailRow>

      <DetailRow label="End Date">
        {templateDetails?.end_date &&
          format(
            new Date(templateDetails?.end_date + "T00:00:00"),
            "dd-MMM-yyyy"
          )}
      </DetailRow>

      <DetailRow label="Last Updated">
        {templateDetails?.updated_at &&
          format(new Date(templateDetails?.updated_at), "dd-MMM-yyyy hh:mm a")}
      </DetailRow>
      <DetailRow label="Package">
        {templateDetails?.product_package_id} -{" "}
        {templateDetails?.product_package?.name} [
        {templateDetails?.product_package?.sort_order}]
      </DetailRow>
      {templateDetails?.product_package?.product_list &&
        Object.values(templateDetails.product_package.product_list).map(
          (product, index) => (
            <SubBlock key={product.id}>
              <DetailRow label="Name">{product.name}</DetailRow>
              <DetailRow label="Version">{product.version}</DetailRow>
              <DetailRow label="Sort Order">{product.sort_order}</DetailRow>
              <DetailRow label="Stage">{product.product_stage}</DetailRow>
            </SubBlock>
          )
        )}

<FormColumn>
        {/* Display the productList */}
        <FormSection>
          <LabelHead>Add On Products</LabelHead>
          {productList &&
            productList.map((product) => (
              <ProductItem key={product.id}>
                <StyledInput
                  value={`${product?.id} #hec ${product?.name} (${product?.sort_order}) ${product?.version || ""} ${product.product_stage}`}
                  readOnly
                />
               
              </ProductItem>
            ))}
        </FormSection>
      </FormColumn>
    </>
  );
};

const DetailRow = ({ label, children }) => (
  <RowContainer>
    <LabelCell>{label}:</LabelCell>
    <ValueCell>{children}</ValueCell>
  </RowContainer>
);

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin: 10px 0px;
  margin-bottom: ${(props) => (props.lastSection ? "100px" : "20px")};
`;

const SubBlock = styled.div`
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const RowContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const LabelCell = styled.div`
  font-weight: bold;
  width: 150px;
`;

const ValueCell = styled.div`
  flex: 1;
`;

// Styled Components
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
`;

const FormSection = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const LabelHead = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;